import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { metaTitle } from '../../util/tabTitle';
import CanvasEditorV2, {
  EditorTooling,
} from '../../components/canvas-editor-v2/CanvasEditorV2';
import { DialogModal } from '../../components';
import { AddAssetForm, AssetAccept } from '../assets/AddAssetForm';
import {
  useImageAssets,
  useVideoAssets,
  useGetSlidData,
  useKvsData,
  useKvsGroup,
} from './hooks';
import { CanvasPreviewModalV2 } from '../../components/canvas-preview-v2/CanvasPreviewModalV2';
import { useUpdateSlide } from '../../api-http/slides-v2';
import io, { Socket } from 'socket.io-client';
import { useAuth } from '../../context/auth-context';
import { WS_URL } from '../../configs';
import { Timeout } from './components/SocketTimeout';
import { SOCKET_EVENTS } from '../../util/constant';
import { UserRole } from '../../types/user.types';
import { SlideLock } from './components/UserEdit';
import { handleRefresh } from '../../util/handleRefresh';

let socket: Socket;
const QUERY_KEYWORD = 'slide_edit';
const { refreshSocket, dataConnection, editSlide } = SOCKET_EVENTS;

const CanvasEditorV2Page: FC = () => {
  metaTitle('Canvas Editor');
  const { id: paramId } = useParams<{
    id: string;
  }>();

  const { currentUser, switchedOrgId } = useAuth();

  const [timeoutModal, setTimeoutModal] = useState(false);
  const [socketId, setSocketId] = useState('');
  const [slideLock, setSlideLock] = useState(false);
  const [editor, setEditor] = useState();

  const {
    slide,
    projectData,
    updatedKvs,
    isLoading: isLoadingGetSlide,
    refetchSlideData,
  } = useGetSlidData(paramId || '');

  useEffect(() => {
    if (currentUser && paramId) {
      refetchSlideData();
      if (!socketId || !socket) {
        const { role, organization } = currentUser;

        socket = io(WS_URL, {
          query: {
            keyword: QUERY_KEYWORD,
            slideId: paramId,
            editingBy: currentUser._id,
            organization:
              role === UserRole.superAdmin ? switchedOrgId : organization._id,
          },
        });
      }

      socket.on('connect', () => {
        setSocketId(socket.id);
      });

      socket.on('disconnect', () => {
        setTimeoutModal(true);
      });

      socket.on(dataConnection, (socketData) => {
        const { data, event } = socketData;

        if (data && event === editSlide) {
          if (
            data[0].isEditing &&
            data[0].editingBy._id != currentUser._id &&
            paramId === String(data[0]._id)
          ) {
            setEditor(data[0].editingBy.name);
            socket.disconnect();
            setTimeoutModal(false);
            setSlideLock(true);
          }
        }
      });
    }
  }, [currentUser, paramId, socketId]);

  const { isLoading, resetImages, ...imageProps } = useImageAssets();
  const {
    isLoading: isLoadingVideos,
    resetVideos,
    ...videoProps
  } = useVideoAssets();

  const { isKvsDataLoading, ...kvsDataProps } = useKvsData();
  const { isKvsGroupsLoading, ...kvsGroupsProps } = useKvsGroup();
  const { isLoading: isLoadingSlideUpdate, mutate, data } = useUpdateSlide();
  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [accept, setAccept] = useState<AssetAccept>(AssetAccept.Images);
  const onFinished = () => {
    setOpenAddAssetModal(false);
    resetImages();
    resetVideos();
  };
  useEffect(() => {
    const handleRefresh = () => {
      socket.emit(refreshSocket, socketId);
    };

    if (socketId) {
      handleRefresh();
      document.addEventListener('click', () => {
        handleRefresh();
      });
    }

    return () => {
      document.removeEventListener('click', handleRefresh);
    };
  }, [data]);

  const [json, setJson] = useState({});

  const isKvsLoading = isKvsGroupsLoading || isKvsDataLoading;

  const isLoadingWidget =
    isLoading ||
    isLoadingVideos ||
    isLoadingGetSlide ||
    isLoadingSlideUpdate ||
    isKvsLoading;
  if (!slide) {
    return null;
  }

  return (
    <>
      <EditorTooling
        isLoadingWidget={isLoadingWidget}
        imageProps={{
          ...imageProps,
          onClickImageUploadButton: () => {
            setOpenAddAssetModal(true);
            setAccept(AssetAccept.Images);
          },
        }}
        videoProps={{
          ...videoProps,
          onClickVideoUploadButton: () => {
            setOpenAddAssetModal(true);
            setAccept(AssetAccept.Videos);
          },
        }}
        slidesProps={{
          projectData: projectData,
          updatedKvs: updatedKvs,
          slide,
          updateSlide: mutate,
          paramId,
        }}
        kvsProps={{
          ...kvsDataProps,
          ...kvsGroupsProps,
          isKvsLoading,
        }}
        onEdited={(json) => {
          if (json) {
            setJson(json);
          }
        }}
        openPreview={openPreview}
      >
        <CanvasEditorV2
          realHeight={slide?.projectData?.deviceInfo?.height || 1080}
          realWidth={slide?.projectData?.deviceInfo?.width || 1920}
          onClickPreview={() => setOpenPreview(true)}
          updateTitle={{ _id: slide?._id, title: slide?.title }}
          socket={socket}
        />
        <DialogModal
          title="Add asset"
          onClose={() => setOpenAddAssetModal(false)}
          open={openAddAssetModal}
        >
          <AddAssetForm accept={accept} onFinished={() => onFinished()} />
        </DialogModal>
      </EditorTooling>
      <DialogModal onClose={() => handleRefresh()} open={timeoutModal}>
        <Timeout refresh={() => handleRefresh()} />
      </DialogModal>

      <DialogModal onClose={() => handleRefresh()} open={slideLock}>
        <SlideLock
          editingUser={editor || 'User'}
          resetSocket={() => handleRefresh()}
        />
      </DialogModal>

      <CanvasPreviewModalV2
        title="Preview"
        onClose={() => setOpenPreview(false)}
        open={openPreview}
        json={json}
      />
    </>
  );
};

export default CanvasEditorV2Page;
