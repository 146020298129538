import React, { FC } from 'react';
import { PageTitle } from '../../../components/PageTitle';
import { metaTitle } from '../../../util/tabTitle';

const SignUpPage: FC = () => {
  metaTitle('Sign Up');
  return (
    <div>
      <PageTitle>Sign up Page</PageTitle>
    </div>
  );
};

export default SignUpPage;
