import axios from 'axios';

import { ADVERTEX_API_URL } from '../configs';
import { Nullable } from '../types';
import { getDisplayToken } from '../util/cacheStorage';
import { getApiError } from './apiService';

const getBearToken = (token: Nullable<string>) =>
  token ? `OrgAuth ${token}` : null;

const axiosDisplayApi = axios.create({ baseURL: ADVERTEX_API_URL });

// In here, we handle the api error
axiosDisplayApi.interceptors.response.use(undefined, (error) =>
  Promise.reject({
    msg: getApiError(error),
    status: error.response.status || 500,
  }),
);

axiosDisplayApi.interceptors.request.use((config: any) => {
  (config as any).headers.authorization = getBearToken(getDisplayToken());
  return config;
});

export { axiosDisplayApi };
