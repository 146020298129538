import React, { FC } from 'react';
import { PageTitle } from '../../../components/PageTitle';
import { metaTitle } from '../../../util/tabTitle';

const ForgotPasswordPage: FC = () => {
  metaTitle('Forgot Password');
  return (
    <div>
      <PageTitle>Forgot Password Page</PageTitle>
    </div>
  );
};

export default ForgotPasswordPage;
