import styled from 'styled-components';

export const FilterSelect = styled.div`
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  input {
    padding: 0;
  }
`;
