import { UserRole } from '../types/user.types';

export const useUserTypes = (role: string) => {
  if (role === UserRole.superAdmin) {
    return [
      {
        name: 'Organization Admin',
        value: UserRole.orgAdmin,
        id: UserRole.orgAdmin,
      },
      {
        name: 'User',
        value: UserRole.user,
        id: UserRole.user,
      },
      {
        name: 'Layout Editor',
        value: UserRole.layoutEditor,
        id: UserRole.layoutEditor,
      },
      {
        name: 'Data Editor',
        value: UserRole.dataEditor,
        id: UserRole.dataEditor,
      },
    ];
  }

  return [
    {
      name: 'User',
      value: UserRole.user,
      id: UserRole.user,
    },
    {
      name: 'Layout Editor',
      value: UserRole.layoutEditor,
      id: UserRole.layoutEditor,
    },
    {
      name: 'Data Editor',
      value: UserRole.dataEditor,
      id: UserRole.dataEditor,
    },
  ];
};

export const useUserRoleName = (role: string) => {
  switch (role) {
    case UserRole.superAdmin:
      return 'Super Admin';
    case UserRole.orgAdmin:
      return 'Org Admin';
    case UserRole.layoutEditor:
      return 'Layout Editor';
    case UserRole.dataEditor:
      return 'Data Editor';
    default:
      return 'User';
  }
};
