import React, { FC, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';

import { queryClient } from '../../../App';
import { useSnack } from '../../../util/useSnack';
import { DialogModal } from '../../../components';
import { APP_ROUTE, formMsg, notifyMsg } from '../../../configs';
import { useSetDeviceDebugURL } from '../../../api-http/devices';

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface DebugUrlModalProps {
  onClose: () => void;
  open: boolean;
  deviceID: string;
  debugURL: string;
}

const APP_ROUTES = [
  'https://app-stage.stec.tv/show',
  'https://app-v3.stec.tv/show',
];

const validationSchema = yup.object({
  debugUrl: yup
    .string()
    .required(formMsg.DEBUG_URL_REQUIRED)
    .test('starts-with-app-route', formMsg.DEBUG_URL_INVALID, (value) => {
      if (!value) return false;
      return APP_ROUTES.some((route) => value.startsWith(route));
    }),
});

const DebugUrlModal: FC<DebugUrlModalProps> = ({
  open,
  onClose,
  deviceID,
  debugURL,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();

  const defaultDebugUrl = `${APP_ROUTE}/show/${deviceID}`;

  const { isLoading, mutate } = useSetDeviceDebugURL({
    onSuccess: async (data) => {
      await formik.setFieldValue(
        'debugUrl',
        defaultDebugUrl === data.debugURL ? '' : data.debugURL,
      );
      await queryClient.invalidateQueries({ queryKey: ['/devices', deviceID] });
      showSuccessSnack(
        defaultDebugUrl === data.debugURL
          ? notifyMsg.DEBUG_URL_REMOVE_REQUEST
          : notifyMsg.DEBUG_URL_REQUEST_SENT,
      );
      if (defaultDebugUrl === data.debugURL) {
        formik.resetForm();
      }
      onClose();
    },
    onError: (err) => showErrSnack(err || notifyMsg.DEBUG_URL_REQUEST_FAILED),
  });

  const formik = useFormik({
    initialValues: {
      debugUrl: defaultDebugUrl === debugURL ? '' : debugURL,
    },
    validationSchema,
    onSubmit: ({ debugUrl }) => {
      mutate({
        deviceID,
        debugUrl,
      });
    },
  });

  useEffect(() => {
    if (debugURL) {
      debugURL === defaultDebugUrl
        ? formik.setFieldValue('debugUrl', '')
        : formik.setFieldValue('debugUrl', debugURL);
    }
  }, [debugURL]);

  return (
    <DialogModal title={'Set Debug Url'} onClose={onClose} open={open}>
      <form onSubmit={formik.handleSubmit}>
        <Box mt={'10px'} />

        <TextField
          fullWidth
          id="debugUrl"
          name="debugUrl"
          value={formik.values.debugUrl}
          onChange={formik.handleChange}
          error={formik.touched.debugUrl && Boolean(formik.errors.debugUrl)}
          helperText={formik.touched.debugUrl && formik.errors.debugUrl}
        />

        <ButtonContainer>
          <Button
            color="error"
            disabled={defaultDebugUrl === debugURL || isLoading || !debugURL}
            variant="outlined"
            onClick={() =>
              mutate({
                deviceID,
                debugUrl: defaultDebugUrl,
              })
            }
          >
            Remove URL
          </Button>

          <Button
            color="primary"
            disabled={isLoading}
            variant="contained"
            type="submit"
          >
            Set URL
          </Button>
        </ButtonContainer>
      </form>
    </DialogModal>
  );
};

export default DebugUrlModal;
