import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Maximize2, ArrowLeftCircle } from 'react-feather';

interface FullScreenModeProp extends PropsWithChildren {
  backUrl?: string;
  hideFullScreenBtn?: boolean;
}

const BtnContainer = styled('div')`
  position: absolute !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FullScreenMode: FC<FullScreenModeProp> = ({
  children,
  backUrl,
  hideFullScreenBtn = false,
}) => {
  const handle = useFullScreenHandle();

  return (
    <>
      <BtnContainer>
        {backUrl ? (
          <Link to={backUrl} style={{ textDecoration: 'none' }}>
            <Button
              style={{ position: 'absolute', zIndex: 99 }}
              onClick={handle.exit}
              variant={'contained'}
            >
              <ArrowLeftCircle size={20} />
            </Button>
          </Link>
        ) : null}
        {!hideFullScreenBtn && (
          <Button
            style={{ position: 'absolute', zIndex: 99, right: 0 }}
            onClick={handle.enter}
            variant={'contained'}
          >
            <Maximize2 size={20} />
          </Button>
        )}
      </BtnContainer>
      <FullScreen handle={handle}>{children}</FullScreen>
    </>
  );
};

export default FullScreenMode;
