import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import reduceChildRoutes from './reduceChildRoutes';
import { NavItemOption } from './types';

const SidebarNavList = (props: { pages: NavItemOption[]; depth: number }) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items: ReactNode[], page: NavItemOption) =>
      reduceChildRoutes({ items, page, currentRoute, depth }),
    [],
  );
  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
