import '@mui/lab/themeAugmentation';

import { createTheme as createMuiTheme } from '@mui/material/styles';
import variants from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';

const createTheme = () => {
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      components: components,
      shadows: shadows,
      typography: typography,
      palette: variants.palette,
    },
    {
      name: variants.name,
      header: variants.header,
      footer: variants.footer,
      sidebar: variants.sidebar,
    },
  );
};

export default createTheme;
