import { FC, useEffect, useState } from 'react';
import { AlertProps, Alert } from '@mui/material';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';

const AlertMui = styled(Alert)`
  ${space}
`;

interface AlertBarProps extends AlertProps, SpaceProps {
  msg?: string;
}

export const AlertBar: FC<AlertBarProps> = ({ msg, ...props }) => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage(msg || '');
    const timer = setTimeout(() => setMessage(''), 8000);
    return () => {
      clearTimeout(timer);
    };
  }, [msg]);
  if (!message) {
    return null;
  }
  return (
    <AlertMui {...props} onClose={() => setMessage('')}>
      {message}
    </AlertMui>
  );
};
