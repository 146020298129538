import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Switch, Typography } from '@mui/material';

import { Nullable } from '../../../types';
import { UserRole } from '../../../types/user.types';

import { Wrapper } from '../styles';
import DebugUrlModal from './DebugUrlModal';
import { notifyMsg } from '../../../configs';
import useSocket from '../../../util/useSocket';
import { useSnack } from '../../../util/useSnack';
import DeviceStatsModal from './DeviceStatsModal';
import { useIsUserRole } from '../../../util/useIsUserRole';
import { EVENTS, SOCKET_EVENTS } from '../../../util/constant';
import {
  useRequestDeviceRemoveCache,
  useRequestDeviceRestart,
  useRequestDeviceStats,
  useUpdateDeviceDebugMode,
} from '../../../api-http/devices';

interface DeveloperOptionsProps {
  debugMode?: boolean;
  debugURL?: string;
}

interface DeveloperButtonProps {
  buttonName: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface DeviceStats {
  cpuUsage: string;
  ttlRam: string;
  availableRam: string;
  networkType: string;
  networkDownloadSpeed: string;
  networkUploadSpeed: string;
  appCacheSize: string;
  [key: string]: any;
}

const DeveloperButton = ({
  buttonName,
  disabled,
  onClick,
}: DeveloperButtonProps) => (
  <Grid item xs={4} display="flex" justifyContent="center">
    <Button
      fullWidth
      variant="contained"
      color="primary"
      style={{
        fontWeight: 'bold',
        fontSize: '12px',
        marginRight: '10px',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonName}
    </Button>
  </Grid>
);

const DeveloperOptions: FC<DeveloperOptionsProps> = ({
  debugMode,
  debugURL,
}) => {
  const { socket } = useSocket();
  const params = useParams<{ id: string }>();
  const { showErrSnack, showSuccessSnack } = useSnack();

  const debugAccess = useIsUserRole([UserRole.superAdmin]);

  const [deviceStats, setDeviceStats] = useState<Nullable<DeviceStats>>(null);
  const [debugUrlModal, setDebugUrlModal] = useState(false);
  const [deviceStatsModal, setDeviceStatsModal] = useState(false);
  const [debugModeSwitchChecked, setDebugModeSwitchChecked] =
    useState(debugMode);

  const { mutate: mutateDebugMode } = useUpdateDeviceDebugMode({
    onSuccess: (res) => {
      setDebugModeSwitchChecked(res.debugMode);
      showSuccessSnack(notifyMsg.DEVICE_DEBUG_MODE_CHANGED_SUCCESSFULLY);
    },
    onError: (err) =>
      showErrSnack(
        err ? err.msg : notifyMsg.FAILED_TO_CHANGE_DEVICE_DEBUG_MODE,
      ),
  });
  const { mutate: mutateRequestDeviceStats } = useRequestDeviceStats({
    onSuccess() {
      showSuccessSnack(notifyMsg.DEVICE_STATS_REQUEST_SENT);
    },
    onError: (err) =>
      showErrSnack(err || notifyMsg.DEVICE_STATS_REQUEST_FAILED),
  });
  const { mutate: mutateRequestDeviceRemoveCache } =
    useRequestDeviceRemoveCache({
      onSuccess() {
        showSuccessSnack(notifyMsg.DEVICE_REMOVE_CACHE_REQUEST_SENT);
      },
      onError: (err) =>
        showErrSnack(err || notifyMsg.DEVICE_REMOVE_CACHE_REQUEST_FAILED),
    });
  const { mutate: mutateRequestDeviceRestart } = useRequestDeviceRestart({
    onSuccess() {
      showSuccessSnack(notifyMsg.DEVICE_RESTART_REQUEST_SENT);
    },
    onError: (err) =>
      showErrSnack(err || notifyMsg.DEVICE_RESTART_REQUEST_FAILED),
  });

  useEffect(() => {
    setDebugModeSwitchChecked(debugMode);
  }, [debugMode]);

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.updateDevice, (socketData) => {
        const { event, data } = socketData;

        if (event === EVENTS.updateUsageData && data[0] === params?.id) {
          setDeviceStats(data[1]);
          setDeviceStatsModal(true);
        }
      });
    }

    return () => {
      if (socket) socket.off(SOCKET_EVENTS.updateDevice);
    };
  }, [params?.id, socket]);

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Developer Options
          </Typography>
        </Grid>

        {debugAccess && (
          <Grid
            item
            xs={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography fontSize={15} fontWeight={500}>
              Debug Mode
            </Typography>
            <Switch
              checked={debugModeSwitchChecked}
              onChange={() => mutateDebugMode(params?.id as string)}
              color="warning"
            />
          </Grid>
        )}

        <Grid container xs={12} marginTop={3} rowGap={2}>
          <DeveloperButton
            buttonName="Check Device Statistics"
            onClick={() => mutateRequestDeviceStats(params?.id as string)}
          />
          <DeveloperButton
            buttonName="Remove App Cache"
            onClick={() => mutateRequestDeviceRemoveCache(params?.id as string)}
          />
          <DeveloperButton
            buttonName="Restart App"
            onClick={() => mutateRequestDeviceRestart(params?.id as string)}
          />
          <DeveloperButton
            buttonName="Debug App With Custom Presentation URL"
            disabled={!debugAccess}
            onClick={() => setDebugUrlModal(true)}
          />
        </Grid>
      </Grid>

      {deviceStats && (
        <DeviceStatsModal
          open={deviceStatsModal}
          onClose={(e) => {
            setDeviceStatsModal(e);
            setDeviceStats(null);
          }}
          deviceStats={deviceStats}
        />
      )}

      <DebugUrlModal
        open={debugUrlModal}
        onClose={() => setDebugUrlModal(false)}
        deviceID={params?.id as string}
        debugURL={debugURL as string}
      />
    </Wrapper>
  );
};
export default DeveloperOptions;
