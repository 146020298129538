import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { List } from '@mui/material';
import navItems, { navSuperAdminItems } from './navMenuItems';
import SidebarNavSection from './SidebarNavSection';

import '../../assets/vendor/perfect-scrollbar.css';
import { useAuth } from '../../context/auth-context';
import { UserRole } from '../../types/user.types';

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav: FC = () => {
  const { currentUser } = useAuth();
  const [menu, setMenu] = useState(navItems);

  useEffect(() => {
    if (currentUser?.role === UserRole.superAdmin) {
      setMenu([...navItems, ...navSuperAdminItems]);
    }
  }, [currentUser]);

  return (
    <PerfectScrollbar>
      <List disablePadding>
        <Items>
          {menu.map((item) => (
            <SidebarNavSection
              key={item.title}
              pages={item.pages}
              title={item.title}
            />
          ))}
        </Items>
      </List>
    </PerfectScrollbar>
  );
};

export default SidebarNav;
