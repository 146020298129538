import { FC, useEffect } from 'react';
import { spacing } from '@mui/system';
import { useFormik } from 'formik';
import { Alert as MuiAlert, TextField as MuiTextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import { authMsg } from '../../../configs';
import { useLoginWithPinCode } from '../../../api-http/displays';
import { useAuth } from '../../../context/auth-context';
import { saveDisplayToken } from '../../../util/cacheStorage';

const validationSchema = Yup.object().shape({
  pincode: Yup.string().required(authMsg.PIN_CODE_REQUIRED),
});

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

interface FormValues {
  pincode: string;
}

const SignInForm: FC = () => {
  const navigate = useNavigate();
  const { pinCode, setPinCode } = useAuth();
  const { isLoading, mutate, error, data } = useLoginWithPinCode();

  useEffect(() => {
    if (data) {
      setPinCode(values.pincode);
      saveDisplayToken(data?.authToken || '');
      navigate('/display-list');
    }
  }, [data]);

  useEffect(() => {
    if (pinCode) {
      navigate('/display-list');
    }
  }, [pinCode]);

  const onSubmit = async (values: FormValues) => {
    if (values) {
      mutate(values);
    }
  };

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik({
      initialValues: {
        pincode: '',
      },
      onSubmit,
      validationSchema,
    });

  return (
    <form noValidate onSubmit={handleSubmit}>
      {error && (
        <Alert mt={2} mb={3} severity="warning">
          {error.msg}
        </Alert>
      )}
      <TextField
        type="pincode"
        name="pincode"
        label="Pin Code"
        value={values.pincode}
        error={Boolean(touched.pincode && errors.pincode)}
        fullWidth
        helperText={touched.pincode && errors.pincode}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: 10 }}
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isLoading}
        loading={isLoading}
      >
        Sign in
      </LoadingButton>
    </form>
  );
};

export default SignInForm;
