import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MeResponse, useGetMeApi } from '../api-http/auth';
import { Nullable } from '../types';

type State = {
  isLoggedIn: boolean;
  currentUser: Nullable<MeResponse>;
  setToken: (token: string) => void;
  logout: () => void;
  pinCode: string | null;
  setPinCode: (pinCode: string) => void;
  refetchMe: () => void;
  switchedOrgId: Nullable<string>;
  setSwitchedOrgId: (orgId: string | null) => void;
};
type AuthProviderProps = { children: React.ReactNode };
import {
  getSavedPinCode,
  getTokenInLocal,
  saveTokenInLocal,
  savePinCode,
  removePinCode,
  saveSwitchedOrgIdInSession,
  getSwitchedOrgIdInSession,
} from '../util/cacheStorage';
import { useQueryClient } from '@tanstack/react-query';

const AuthStateContext = createContext<State | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<Nullable<MeResponse>>(null);
  const [switchedOrgId, setSwitchedOrgId] = useState<Nullable<string>>(
    getSwitchedOrgIdInSession(),
  );
  const [token, setToken] = useState(getTokenInLocal());
  // TODO: Remove after display app is developed
  const [pinCode, setPinCode] = useState(getSavedPinCode());
  const queryClient = useQueryClient();
  const { refetch, error, data: meData } = useGetMeApi();

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    queryClient.clear();
    queryClient.resetQueries();
    setCurrentUser(null);
    setToken(null);
  };

  useEffect(() => {
    if (token) {
      refetch();
    }
    saveTokenInLocal(token || '');
  }, [token, refetch]);

  useEffect(() => {
    if (error?.status === 401) {
      logout();
    }
  }, [error]);

  useEffect(() => {
    if (meData) {
      setCurrentUser(meData);
    }
  }, [meData]);

  useEffect(() => {
    saveSwitchedOrgIdInSession(switchedOrgId);
  }, [switchedOrgId]);

  // TODO: Remove after display app is developed
  useEffect(() => {
    if (pinCode === '') {
      removePinCode();
    } else {
      savePinCode(pinCode || '');
    }
  }, [pinCode]);

  return (
    <AuthStateContext.Provider
      value={{
        switchedOrgId,
        currentUser,
        setSwitchedOrgId,
        setToken,
        isLoggedIn: !!token,
        refetchMe: refetch,
        logout,
        pinCode,
        setPinCode,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export { AuthProvider, useAuth };
