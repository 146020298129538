import React, { FC, useEffect } from 'react';
import {
  Typography,
  CardContent,
  Card,
  CardActionArea,
  LinearProgress,
  AppBar,
  Toolbar,
  Box,
  IconButton,
} from '@mui/material';
import styled from 'styled-components';
import { useAuth } from '../../../context/auth-context';
import {
  GetDisplayPresentationsResponse,
  useGetDisplayPresentations,
  useLoginWithPinCode,
} from '../../../api-http/displays';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertBar } from '../../../components/AlertBar';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { saveDisplayToken } from '../../../util/cacheStorage';
import { metaTitle } from '../../../util/tabTitle';

const MainWrapper = styled.div`
  background-color: #e7ebf0;
  min-height: 100vh;
`;

const CardsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 40px;
`;

const CustomCard = styled(Card)`
  &:hover {
    box-shadow: 0 2px 21px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
`;

const DisplayList: FC = () => {
  metaTitle('Presentation List');

  const { pinCode, setPinCode } = useAuth();
  const { isLoading, mutate, error, data } = useLoginWithPinCode();
  const {
    isLoading: getPresentationIsLoading,
    error: getPresentationError,
    data: getPresentationData,
  } = useGetDisplayPresentations();

  const navigate = useNavigate();

  const [presentationsList, setPresentationsList] = useState<
    GetDisplayPresentationsResponse[]
  >([]);

  useEffect(() => {
    if (pinCode) {
      mutate({ pincode: pinCode });
    } else {
      navigate('/display-login');
    }
  }, [pinCode]);

  useEffect(() => {
    if (data) {
      saveDisplayToken(data?.authToken || '');
    }
  }, [data]);

  useEffect(() => {
    if (getPresentationData) {
      setPresentationsList(getPresentationData);
    }
  }, [getPresentationData]);

  if (error || getPresentationError) {
    return (
      <AlertBar
        severity="error"
        msg={error?.msg || getPresentationError?.msg}
      />
    );
  }

  if (isLoading || getPresentationIsLoading) {
    return <LinearProgress />;
  }

  const cards = presentationsList.map((presentation) => {
    const { name, _id, slides } = presentation;
    return (
      <CustomCard
        key={_id}
        sx={{ minWidth: 275, textAlign: 'center', padding: '20px' }}
        onClick={() => {
          navigate(`/presentation-public-preview/${_id}`);
        }}
      >
        <CardActionArea>
          <CardContent>
            <Typography variant="h5" component="div">
              {name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Sides: {slides.length}
            </Typography>
          </CardContent>
        </CardActionArea>
      </CustomCard>
    );
  });

  const logout = () => {
    setPinCode('');
    navigate('/display-login');
  };

  return (
    <MainWrapper>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Select your presentation
            </Typography>
            <IconButton
              size="large"
              aria-label="logout"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={logout}
              color="inherit"
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <CardsWrapper>{cards}</CardsWrapper>
    </MainWrapper>
  );
};

export default DisplayList;
