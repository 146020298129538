import React, { FC, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import {
  Alert as MuiAlert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';

import { useLogin } from '../../../api-http/auth';
import { authMsg } from '../../../configs';
import { useAuth } from '../../../context/auth-context';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(authMsg.EMAIL_INVALID)
    .max(255)
    .required(authMsg.EMAIL_REQUIRED),
  password: Yup.string().max(255).required(authMsg.PASSWORD_REQUIRED),
});

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

interface FormValues {
  email: string;
  password: string;
  submit: boolean;
}

const SignInForm: FC = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const { isLoading, mutate, error, data } = useLogin();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (data?.authToken) {
      setToken(data.authToken);
      navigate('/');
    }
  }, [data]);

  const onSubmit = async (values: FormValues) => {
    if (values) {
      mutate({ email: values.email, password: values.password });
    }
  };

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
        submit: false,
      },
      onSubmit,
      validationSchema,
    });

  return (
    <form noValidate onSubmit={handleSubmit}>
      {error && (
        <Alert mt={2} mb={3} severity="warning">
          {error.msg}
        </Alert>
      )}
      <TextField
        type="email"
        name="email"
        label="Email Address"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        fullWidth
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: 10 }}
      />
      <FormControl
        variant="outlined"
        fullWidth
        error={Boolean(touched.password && errors.password)}
      >
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        <FormHelperText error id="accountId-error">
          {errors.password}
        </FormHelperText>
      </FormControl>

      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label={'Remember me'}
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isLoading}
        loading={isLoading}
      >
        Sign in
      </LoadingButton>
    </form>
  );
};

export default SignInForm;
