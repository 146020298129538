import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';

export const StyleAutocomplete = styled(Autocomplete)`
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  input {
    padding: 0;
  }
` as typeof Autocomplete;
