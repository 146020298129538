import { useMutation, useQuery } from '@tanstack/react-query';

import { BaseUserWithOrg } from '../types/user.types';
import { ApiServiceErr, axiosApi } from './apiService';

interface ResetPasswordBody {
  id: string;
  newPassword: string;
}

export const useGetAdminUsers = () =>
  useQuery<BaseUserWithOrg[], ApiServiceErr>(['admin-users'], async () => {
    const response = await axiosApi.get('/users/admin-users');
    return response.data;
  });

export const useAdminResetPassword = () =>
  useMutation<string, ApiServiceErr, ResetPasswordBody>(async (data) => {
    const response = await axiosApi.put('/users/reset-password', data);
    return response.data;
  });
