import { fabric } from 'fabric';
import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useEffect,
} from 'react';
import useUndo, {
  State as HistoryState,
} from '../../../../common/editor-hooks/useUndo';
import { SelectEvent, SelectMode } from '../../../types/types';

const DEFAULT_TAB = 'Text';
const DEFAULT_COLOR = 'white';

type State = {
  canvas: fabric.Canvas | null;
  setCanvas: (canvas: fabric.Canvas) => void;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  activeColor: any;
  setActiveColor: (activeTab: any) => void;
  history: HistoryState<fabric.Object[][] | object>;
  setHistory: (history: any) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  initLoad: boolean;
  setInitLoad: (init: boolean) => void;
  copiedObjects: fabric.Object[];
  setCopiedObjects: (copiedObjects: any) => void;
  element: string;
  setElement: (elem: string) => void;
  selectMode: SelectMode | '';
  searchAsset: string;
  setSearchAsset: (search: string) => void;
};

const Context = createContext<State | null>(null);

function EditorProvider({ children }: PropsWithChildren) {
  const [canvas, setCanvas] = useState<State['canvas']>(null);
  const [activeTab, setActiveTab] = useState<State['activeTab']>(DEFAULT_TAB);
  const [activeColor, setActiveColor] =
    useState<State['activeTab']>(DEFAULT_COLOR);
  const [history, { set: setHistory, undo, redo, canUndo, canRedo }] =
    useUndo<any>([]);
  const [initLoad, setInitLoad] = useState<boolean>(false);
  const [copiedObjects, setCopiedObjects] = useState<fabric.Object[]>([]);
  const [element, setElement] = useState('');
  const [selectMode, setSelectMode] = useState<SelectMode | ''>('');
  const [searchAsset, setSearchAsset] = useState('');

  const listener = (e: Event) => {
    const selectEvent = e as CustomEvent;
    setSelectMode(selectEvent.detail);
  };

  useEffect(() => {
    if (selectMode && activeTab !== 'CSS Animations') {
      setActiveTab('Settings');
    } else if (activeTab === 'Settings') {
      setActiveTab('Text');
    }
  }, [selectMode]);

  useEffect(() => {
    document.addEventListener(SelectEvent.ONE, listener);
    return document.removeEventListener('resize', listener);
  }, []);

  return (
    <Context.Provider
      value={{
        canvas,
        setCanvas,
        activeTab,
        setActiveTab,
        activeColor,
        setActiveColor,
        history,
        setHistory,
        undo,
        redo,
        canUndo,
        canRedo,
        initLoad,
        setInitLoad,
        copiedObjects,
        setCopiedObjects,
        element,
        setElement,
        selectMode,
        searchAsset,
        setSearchAsset,
      }}
    >
      {children}
    </Context.Provider>
  );
}

function useEditorData() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useEditorData must be used within a EditorProvider');
  }
  return context;
}

export { EditorProvider, useEditorData };
