const TOKEN = 'token';
const DISPLAY_TOKEN = 'display_token';
const PIN_CODE = 'pin_code';
const SWITCHED_ORG_ID = 'switched_org_id';

export const getSwitchedOrgIdInSession = () =>
  localStorage.getItem(SWITCHED_ORG_ID);

export const saveSwitchedOrgIdInSession = (switchedOrgId: string | null) =>
  localStorage.setItem(SWITCHED_ORG_ID, switchedOrgId || '');

// Original token
export const getTokenInLocal = () => localStorage.getItem(TOKEN);

export const saveTokenInLocal = (userToken: string) =>
  localStorage.setItem(TOKEN, userToken);

// Display token
export const getDisplayToken = () => localStorage.getItem(DISPLAY_TOKEN);

export const saveDisplayToken = (displayToken: string) =>
  localStorage.setItem(DISPLAY_TOKEN, displayToken);

export const getSavedPinCode = () => localStorage.getItem(PIN_CODE);

export const savePinCode = (pinCode: string) =>
  localStorage.setItem(PIN_CODE, pinCode);

export const removePinCode = () => localStorage.removeItem(PIN_CODE);
