import { BaseOrganization } from './organization.types';

export enum UserRole {
  superAdmin = 'superAdmin',
  orgAdmin = 'organizationAdmin',
  layoutEditor = 'layoutEditor',
  dataEditor = 'dataEditor',
  user = 'user',
}

export interface BaseUser {
  _id: string;
  name: string;
  email: string;
  role: UserRole;
  createdAt: string;
  updatedAt: string;
}

export interface BaseUserWithOrg extends BaseUser {
  organization: BaseOrganization;
}
